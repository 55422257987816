import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { withPrefix } from 'gatsby'
import Helmet from 'react-helmet'

import FullPageSection from '../components/FullPageSection'
import HeroTitle from '../components/HeroTitle'
import Footer from '../components/Footer'
import Header from '../components/HeaderEnglishOnly'

import bgImage from '../img/tlc-issues.jpg'
import flagImage from '../img/flagImage.png'

const GreenNewDeal = (props) => (
  <main>
    <Helmet>
      <script
        src={withPrefix('/scripts/toc.js')}
        type="text/javascript"
        defer
      />
    </Helmet>
    <Header className="bg-white" />
    <h1 className="tmp-policy-title">A Green New Deal For NYC</h1>
    {/* <img src={flagImage} className='flagImage' height="auto" /> */}
    <p className="tmp-policy-pdf">
      <a href="/files/green-new-deal.pdf">PDF version of Policy Page</a>
    </p>

    <div className="tmp-public-safety">
      <div className="tmp-public-safety-top-toc"></div>

      <div className="tmp-public-safety-grid">
        <div className="tmp-public-safety-content">
          <section id="quotes">
            <h2>ABSTRACT / SUMMARY</h2>
            <section>
              <p>
                The Green New Deal, and ‘Build Back Better,’ will utilize a
                system of cooperation and coordination among levels of
                government in which each level does what it does best. The
                federal government is best at (1) financing – thanks to the vast
                lending, borrowing, and spending power of the U.S. Fed and
                Treasury – and (2) harmonizing efforts among multiple states and
                localities to ensure they cohere rather than undercutting each
                other. States and localities are best at determining local needs
                and imperatives that are not understood in Washington.
              </p>
            </section>

            <section>
              <p>
                The Green New Deal will be largely funded and nationally
                coordinated in Washington, even while funding and nationally
                harmonizing projects determined by and large locally. New York,
                including New York City and District 22, will have both
                opportunities and responsibilities.
              </p>
            </section>

            <section>
              <p>
                The opportunity that the Green New Deal will present the City
                and the District is that of substantial federal funding for
                ambitious Green New Deal projects in NYC generally and D22
                particularly. The corresponding responsibility this will place
                on NYC and D22 is to conceive and prioritize projects. We as a
                community must determine what we most need to do to make our
                city and community healthy, just, and sustainable in the way
                that the Green New Deal as a whole is meant to make our country
                itself healthy, just, and sustainable.
              </p>
            </section>

            <section>
              <p>
                This process must be participatory. It must center the voices of
                impacted communities. It must recognize environmental harms
                perpetuated in systemically racist ways, and it must address
                those harms through reparative and restorative approaches, as
                well as through forward-facing equity-focused solutions. In
                pursuing these solutions, we can create at least 100,000 green
                jobs, prioritize training in impacted communities for those
                jobs, and deliver a new care-based economy that is rooted in
                sustainability and the kind of infrastructural investments that
                enable our city to be carbon-neutral by 2030.
              </p>
            </section>

            <section>
              <p>
                Our district, and districts across the city must meet together
                in multiple town hall meetings over the next several months to
                decide upon our most urgent Green New Deal needs. Only a
                holistic, roundtable approach that starts from a place of
                community-led decision-making can lead us to a green New York
                City.
              </p>
            </section>
          </section>
          <section id="summary">
            <h2>BACKGROUND</h2>

            <p>
              The text of the Green New Deal Resolution introduced in 2019 by
              Congress Member Alexandria Ocasio-Cortez and Senator Edward Markey
              is a framework and a call to action. It calls on us to not only
              decarbonize each sector of our economy, but to also achieve
              environmental justice and a maximum amount of jobs for communities
              that have been ravaged thanks to the fossil fuel industry. The
              Cabán for Council Green New Deal is centered around reparations,
              jobs and pandemic recovery.
            </p>

            <p>
              Our plan calls for: Passage of and expansion on the Renewable
              Rikers Act; shade trees and heat island treatment plans; renewal
              of the Big Reuse facility’s lease, as well as expansion of food
              scrap drop off sites so that every person in our district is
              within 10 minutes’ walk of a site; flood-proofing and
              resilience-building across our coastlines; permanent open streets
              and green spaces based on successful models from NYC to Barcelona;
              comprehensive renovation of our outdated sewage system; new
              protected bike and bus lanes, as well as renovations to ensure
              accessibility for people with disability or people who are older;
              and, across the board, substantial and sustained infrastructure
              and job investments that increase our city’s renewable energy
              capacity as well as our ability to provide union jobs for
              environmental purposes.
            </p>
          </section>
          <section id="introduction">
            <h2>Reparations</h2>
            <p>
              Most discussion of climate reparations has taken place on an
              international scale, with advocates calling for wealthier nations
              that have profited most from fossil fuel consumption to pay for
              climate change mitigation and adaptation in poorer countries now
              bearing the brunt of the climate crisis. As this idea gains
              momentum, experts and activists have advocated a similar framework
              of justice on a local scale.
            </p>

            <p>
              In New York and elsewhere, state and local governments have sued
              oil companies for their contributions to climate change. While
              these lawsuits are justified, they are insufficient; for example,
              Mayor de Blasio’s federal lawsuit against the five biggest
              publicly traded oil companies was thrown out.
            </p>

            <p>
              More effective climate reparations involve direct public
              investments in communities affected by environmental injustice.
              This approach recognizes “historical environmental racism and
              intersectional drivers of precarious lives, social trauma, and
              displacement beyond those narrowly associated with ‘climate.’ It
              embeds climate debt in longer historical time frames of
              infrastructural reparations.” Climate reparations must include
              investments in economic opportunity and infrastructure in
              historically marginalized communities, as well as initiatives to
              eliminate pollution, create green spaces and improve public health
              in those same communities.
            </p>

            <p>
              Such projects can take many forms in D22. For example, instead of
              giving vacant lots to real estate developers, such land should be
              used to build affordable housing units or communal parks.
              Meanwhile, Council must work with Albany to pass higher taxes on
              real estate developers and the wealthiest New Yorkers to help
              finance green projects, on top of federal funding. Climate
              reparations means holding energy companies accountable for abuses
              and using revenues from fines to compensate overcharged tenants.
            </p>

            <p>
              Climate reparations includes recognizing historical environmental
              injustices and centering communities in redressing them. The New
              York City government should acknowledge environmental harms
              through public education and public apology, which will in turn
              raise awareness and support for climate reparations. Ultimately,
              community members’ climate reparation priorities should shape
              funding priorities.
            </p>
          </section>
          <section id="community-safety-centers">
            <h2>Jobs and Pandemic Recovery</h2>
            <p>
              The Covid pandemic has ravaged New York’s small businesses, and
              the federal stimulus package in addition to future legislation
              could provide relief. This money should be spent to bring more
              resilient small businesses, responsive to their local communities,
              back to New York. Our city should foster the creation and
              spreading of employee-owned firms, and other firms whose owners
              reside where their places of business are located, in both the
              City generally and D22 particularly.
            </p>
            <p>
              Labor sourcing can also be addressed through Council legislation.
              An equitable recovery would include training programs for a new
              green construction force with good-paying union jobs, which will
              be essential in the coming years through NYC. Union protections,
              good wages,and safe conditions should be fundamental to these
              projects. Therefore, the Council should tie city funding to
              project-labor agreement (PLA) usage. PLAs ensure union coverage on
              jobsites, increasing safety and ensuring communities benefit
              through job creation. As the city will be providing loans to many
              building owners to fund work in order to meet compliance, these
              loans can be tied to PLA usage. An act from the Council requiring
              this would bring a boom to the union construction industry while
              benefiting all workers on these job sites. An alternative plan
              might scale up project bundling (i.e. bring three nearby
              apartments looking for green loans into the same project) and
              mandate PLAs among those nearby job sites, while establishing
              mechanisms to maintain local control over project implementation.
              Low interest rates from the city could incentivize this and
              provide further reasons to conduct this work.
            </p>
            <p>
              The City Council should also facilitate youth hiring through
              expansion of the Summer Youth Employment Program (SYEP) to offer
              more local environmental jobs, especially in environmental justice
              communities such as D22.
            </p>

            <section id="family-supports">
              <h2>ACTION PLANS</h2>
              <p>
                <strong>
                  Process: City and District Green New Deal Issues Meetings
                </strong>
              </p>
              <p>
                Our office will work with neighborhood, school, faith, and other
                civic leaders throughout D22 to set up town halls that establish
                sustainability needs to be addressed neighborhood by
                neighborhood.
              </p>
              <p>
                The issues constituents raise at each meeting will be compiled
                into a comprehensive and coherent D22 Green New Deal issues
                list. The office will regularly and transparently provide status
                updates on every item and the remaining steps necessary to
                secure funding. A youth climate council reflecting the diversity
                of D22 will advise the office on climate policy.
              </p>
              <p>
                The Green New Deal will never be a ‘done deal.’ It is a process
                of continual local renewal that is guided by ongoing democratic
                dialogue. Local issues meetings will be ongoing, to meet the
                evolving needs and wishes of the City’s and District’s own
                residents.{' '}
              </p>

              <h2>Possible Green New Deal Projects</h2>
              <p>
                <strong>Green New Deal Projects for the 22nd District:</strong>
              </p>

              <h3>Power Generation:</h3>
              <p>
                <strong>Background: </strong>Fossil-fuel plants, and peaker
                plants in particular, release air pollutants that affect the
                health of all residents of D22. NYC neighborhood health profiles
                report increased rates of asthma and higher levels of fine
                particulate matter in our District compared to the NYC average.
                That is the source of the nickname ‘Asthma Alley’ for Astoria.
              </p>
              <p>
                The Renewable Rikers Act was signed into law on Feb. 25, 2021.
                It is a package of legislation that transfers a portion of
                Rikers Island from the Department of Corrections (DOC) to the
                Department of Citywide Administrative Services (DCAS) and
                initiates a study of renewable energy generation and storage
                there.{' '}
              </p>
              <p>
                <strong>Solutions: </strong>The Renewable Rikers conversion must
                be a top priority. It is urgent and critical that we replace
                ‘peaker plants’ with renewable sources of power generation,
                including Renewable Rikers, as quickly as possible. For example,
                we can develop an electricity storage system on Rikers Island
                that integrates renewable generation and helps meet continually
                varying electricity demand across New York City.
              </p>
              <p>
                The transition to a Renewable Rikers can also provide
                reparations to marginalized communities by prioritizing jobs for
                past victims of our criminal legal system and by providing
                clean, cheap power to communities like those in D22 that have
                had historically high rates of air pollution. Funds to
                transition D22 away from fossil fuel peaker plants should also
                be used to build free public health clinics in D22, where
                victims of particulate matter in Astoria’s “Asthma Alley”
                deserve reparations for the health consequences they have
                suffered due to oil-burning peaker plants.
              </p>

              <h3>
                Shade Trees and Environmentally Safe Fans & Air Conditioning:
              </h3>
              <p>
                <strong>Background: </strong>Our District contains many heat
                islands – areas that are typically 2-3°F warmer than the average
                temperature in NYC – in multiple locations. Resident’s of the
                D22 are accordingly more vulnerable to heat-related deaths and
                health complications than is the typical New Yorker. Making the
                problem worse is the fact that 6-7% of homes in the D22 do not
                have air conditioners. The existence of heat islands has also
                been linked to previously redlined districts, meaning that this
                priority has reparations significance in addition to general
                quality of life significance.
              </p>
              <p>
                <strong>Solutions: </strong>Our city should engage in a
                comprehensive effort to plant shade trees in every neighborhood,
                and provide state-of-the-art, environmentally sustainable fans
                or air conditioners in all of D22’s ‘heat islands.’ We could
                increase funds to NYC Parks in order to expand the NYC Street
                Tree Planting program. The city should expand the Home Energy
                Assistance Program (HEAP) Cooling Assistance Component benefit,
                which currently offers a limited number of free air conditioners
                for people with medical conditions. It should also expand the
                NYC Cooling Assistance benefit that helps eligible households
                buy and install an air conditioner or fan up to a cost of $800,
                prioritizing vulnerable communities like nursing homes to meet
                the target community needs above.
              </p>
              <p>
                These programs should coordinate with NYCHA to expand and
                improve upon their ‘Smart Window Air Conditioners” and “Air
                Source Heat Pumps” pilot programs in order to bring energy
                efficient cooling systems to every NYCHA resident.
              </p>

              <h3>Composting & Recycling: </h3>
              <p>
                <strong>Background: </strong>Composting and recycling reduce
                greenhouse gas emissions and create job opportunities. D22 has
                been on the frontline of the multiyear struggle to get the city
                government to take composting seriously, and through the work of
                on-the-ground organizers has become a composting leader in a
                city that right now lacks infrastructure necessary to meet its
                carbon neutral goals. Our district should be the model for how
                the city develops and implements its composting and recycling
                infrastructure. We saw firsthand that political rhetoric on
                composting often does not live up to on-the-ground reality –
                just one painful example is the much-promoted curbside pickup
                program that initially saw $20 million in funding that slowly
                dwindled while the on-the-ground program continues to fail to
                meet its goals. Climate activists have worked hard to ensure
                composting accessibility despite the city’s failure to do so;
                now, most of District 22 is within walking distance of food
                scrap drop off sites.
              </p>
              <p>
                The problems with the curbside pickup program and the political
                failure to ensure its success means elected officials are
                obligated to prove their credibility on climate, and the best
                way to do that is to directly empower the people and
                organizations in D22 doing the work that is making our district
                a citywide leader in composting and recycling. . That work has
                been substantially more difficult during the pandemic, with
                curbside pickup suspended entirely and many food scrap drop-off
                sites shut down. Because of work by groups like Astoria Pug and
                others, D22 has as many food scrap drop off sites as any other
                district with 9. According to Astoria Pug, right now roughly 87%
                of District 22 lives within 10 minutes of a food scrap drop off
                site, a testament to the power of organizing in climate advocacy
                groups.
              </p>
              <p>
                At the same time, our core composting capacity is at dire risk
                as the current Parks Department is refusing to renew the lease
                of the Big Reuse composting facility. Big Reuse is responsible
                for 70% of our area’s food scrap recycling capacity – if the
                Parks Department insists on evicting Big Reuse even though it
                has regularly renewed leases in the past, the result will be our
                district simply cannot make meaningful progress toward carbon
                neutrality until a similar facility is established. In other
                words, the fate of our city, the status of D22 as a green leader
                in the city, and our long-term capacity-building goals in terms
                of resilience and recycling are all being put at risk by an
                arbitrary decision from the Parks Department.
              </p>
              <p>
                <strong>Solutions: </strong>Big Reuse must not be evicted; the
                Parks Department must renew the lease right away. The eviction
                of Big Reuse would single-handedly eliminate D22’s status as
                climate leader while simultaneously putting our composting
                capacity as a city at serious risk. These are core issues – if
                we fail to do this basic step, we may as well admit our city
                will not be carbon neutral by 2030. There is no carbon neutral
                NYC without a renewed Big Reuse lease.
              </p>
              <p>
                Beyond securing the future of Big Reuse, it is past time that we
                reopen, expand and create new food scrap and recycling drop-off
                sites throughout the District and provide home composting
                containers and recycling bins to D22 residents who wish to use
                them. Every building should have access to a composting and
                recycling site, and every neighborhood should have access to
                large-scale sites that can handle the city’s goal to get to zero
                waste by 2030.
              </p>

              <p>
                We must simultaneously engage in targeted expansion of food
                scrap and recycling drop-off sites in order to reduce the amount
                of walk time and increase widespread education, support, and
                adoption. Our goal should be that 100% of D22’s population is
                within a 10 minute walk of a food scrap drop off site over the
                next two years, and we should be making serious strides toward
                increasing the share of the population within 5 minutes of a
                drop-off site over these next two years as well. We can do it by
                funding with the groups on the ground already doing the work.
                People want to recycle and compost when it is accessible and
                convenient – we should guarantee that accessibility and
                convenience.
              </p>

              <p>
                We also have to make our recycling efforts more holistic and
                interconnected across agencies and government programs. For
                example, the DOE Office of Sustainability also runs a Zero Waste
                Pledge Schools Program. In FY20, 46 educators reached 23 schools
                to build recycling and waste reduction efforts, leaving over a
                thousand schools without the same guidance. City Council should
                provide funding to expand the Zero Waste Program, especially to
                D22 schools in typically low-income communities historically
                underserved by the city’s waste services. Establishing a culture
                of responsibility on both the sides of students and their city
                government now will help ensure sustainable and proper waste
                management later.
              </p>

              <h3>Flood-Proofing:</h3>
              <p>
                <strong>Background: </strong>Our District is vulnerable to
                hurricane flooding. Resilient Neighborhoods – a place-based
                planning initiative to identify neighborhood-specific
                strategies, including zoning and land use changes, to support
                the vitality and resiliency of communities in the floodplain and
                prepare them for future storms – does not currently plan any
                projects for our District. We can and must act both to change
                that and to develop measures of our own within D22.{' '}
              </p>
              <p>
                <strong>Solutions: </strong>We must convene roundtables of
                experts, communities, and advocates to develop flood-mitigation
                plans and facilities across the city including for D22,
                including floodwalls, better drainage, and flood-proofing
                building technology.{' '}
              </p>
              <p>
                We should also accelerate production of a resiliency plan for
                D22 that considers land use and zoning. These plans should hold
                developers accountable for preventing inland flooding using a
                holistic flood proofing plan that doesn’t protect luxury high
                rises at the expense of inland neighborhoods.
              </p>

              <h3>Sewage Projects:</h3>
              <p>
                <strong>Background: </strong>There are currently no sewage
                projects listed in the City’s capital budget that fall solely
                within D22. There are millions authorized to borough-wide
                projects, but whether this will be adequate for D22 needs cannot
                be known until we assess our own needs.
              </p>
              <p>
                <strong>Solutions: </strong>D22 should proactively assess our
                current and likely future sewage needs and determine whether
                current capacity is sufficient. This includes the construction
                upgrades of sanitary and storm sewers, water mains and
                appurtenances, the emergency reconstruction of sewers damaged by
                Sandy and other severe weather events, and improving storm
                drainage systems to improve sanitation and sustainability.
              </p>

              <h3>Permanent Open Streets and Green Spaces</h3>
              <p>
                <strong>Background: </strong>We deserve to have safe, open and
                clean public space. D22 streets have flourished since the city
                closed them off to most car traffic. Open Street policies allow
                local areas to become a safe space to exercise and make local
                parks a more relaxing environment. Open Street policies allow
                pedestrians, children, our neighbors with mobility assistive
                devices, cyclists, and families to enjoy a public space without
                excessive cars, noise, pollution, or danger.
              </p>
              <p>
                <strong>Solution: </strong>We must create and expand permanent
                open streets and green space for arts and cultural venues,
                restaurant seating, street vendors, retail, and other uses to
                bolster economic recovery. The city needs to expand safe open
                streets for pedestrians, so that our streets can be used for
                walking, for children to play and for outdoor dining, cultural
                events and retail. In particular, we must expand open streets in
                historically marginalized communities to ensure a more equitable
                distribution of public space.
              </p>
              <p>
                We will work with community stakeholders and government
                officials to make New York’s successful experiment in open
                streets permanent and larger. The city should create 1,000 lane
                miles of permanent Open Streets, a dramatic increase from Mayor
                de Blasio’s pledge of 100 miles. This Open Streets plan should
                include large pedestrian-only zones on major thoroughfares and
                in central business districts, including in D22. In the interim,
                we must provide financial and material resources for the
                continued maintenance and logistics of a volunteer-led open
                streets program that has been so successful to date. Community
                participation in Open Streets enables neighborhoods and
                community groups to define their own needs for the open space.
              </p>

              <h3>Transit & Biking</h3>
              <p>
                <strong>Background: </strong>We can improve living conditions
                for D22 residents by improving air quality, reducing noise
                pollution, encouraging social and physical activity, and
                increasing accessibility through transit and biking upgrades. In
                order to advance these priorities and meet our climate goals, we
                must reduce car and truck transportation, which are the city’s
                second-largest source of greenhouse gas emissions. As a start,
                we could convert 25 percent of the space currently devoted for
                cars into space for people. This means creating 500 lane miles
                of bus-only lanes and 500 lane miles of protected bike paths.
                Creating 500 additional miles of bus and bike lanes would ensure
                every New Yorker lives within a quarter mile of a bus and bike
                lane connected to a citywide arterial network. Experience has
                shown that where we convert just one lane of car traffic into a
                car-free busway, we can quickly increase bus ridership by 17% or
                more and make commutes significantly faster. Reliable,
                accessible and safe transportation alternatives will ensure we
                do our part to meet NYCs climate goals.{' '}
              </p>
              <p>
                <strong>Solution: </strong>In order to encourage wider use of
                public transportation, we must also fund capital projects to
                repair and improve tunnels, bridges, trains and buses, to ensure
                faster, more reliable commutes. New York City must use relief
                funds from the federal government to fully fund its capital
                infrastructure program, which will cost at least $12 billion.
                Capital projects should include investments in renewable energy,
                for example, to ensure that in D22 and beyond every bus shelter
                is powered by solar power and every subway station has
                regenerative elevators. In addition the MTA must accelerate its
                commitment to switch to an all-electric bus fleet by 2040.
              </p>

              <p>
                <strong>Green New Deal Projects for the Full City:</strong>
              </p>

              <h3>Citywide Counterparts to D22 Projects:</h3>
              <p>
                To begin with, many of the City’s other Districts will need
                projects like those she has suggested above for D22. The Mayor,
                the Comptroller, and the Council should facilitate counterpart
                projects throughout the City. Every District in the City must be
                individually addressed and included, in ways demanded by
                residents, in the City’s Green New Deal. City-wide building
                retrofitting is critical given existing legislation largely
                avoids touching, affordable housing, and the improvements to NYC
                department of education to ensure a sustainable future for all
                children of New York City.
              </p>

              <h3>Retrofitting Buildings:</h3>
              <p>
                <strong>Background: </strong>In 2019, the New York City Council
                passed Local Law 97 (LL97), an act that would cut large building
                emissions by 40% of 2005 levels, leading up to a 2030 deadline.
                While LL97 is a revolutionary act, there are still some blind
                spots to address, such as LL97 compliance requirements for
                buildings; the decarbonization of public and affordable housing;
                and the allocation of jobs and workforce development funds.
              </p>
              <p>
                Effective building compliance requirements are at risk with
                LL97. The Office of Building Energy and Emissions Performance
                (OBEEP) has been established to oversee standards and conduct
                rulemaking. Within the next year, OBEEP will issue
                recommendations on alternate compliance applications, such as
                whether or not energy credits will count towards compliance with
                building emissions limits. These alternatives would water down
                standards and give building owners new ways to skirt
                regulations, while hurting underserved communities by shifting
                the cost from carbon emitters in those communities.
              </p>
              <p>
                Council funding for NYCHA retrofits is essential. NYCHA
                buildings consume 40%-50% more energy per square foot than the
                typical NYC multi-family building, while providing unhealthy
                home environments that lack comfort and safety. NYCHA residents
                face greater asthma rates, worse health conditions, and more
                dangerous settings than more efficient neighborhoods, and the
                Council must step in to end this. Alongside public housing,
                affordable housing has been mostly cut out from LL97
                requirements. Specifically, building owners of buildings with
                more than 35% “affordable” units will not have to improve energy
                efficiency and air quality standards anytime soon. If not
                addressed, this will further exacerbate inequitable
                environmental harms for people living in affordable housing.
              </p>
              <p>
                Federal Building Back Better funding will help retrofit these
                buildings, which will require jobs. If we invest $7 billion over
                three years into energy efficiency infrastructure projects in
                New York City, it could create 42,210 direct and indirect jobs.
                This will require much labor and hence offer many new job
                opportunities to those in the D22 who are un- or under-employed.
                New York City’s building emissions crisis can only be resolved
                through bottom-up economic movement.
              </p>
              <p>
                <strong>Solutions: </strong> The Council needs to codify LL97
                mechanisms and applications, stating that the only way to be in
                compliance is to invest in NYC’s housing stock. This will ensure
                building owners live up to the spirit of LL97 and perform actual
                and meaningful improvements to the building’s carbon emissions
                output. However, to properly meet these goals, OBEEP needs to
                ensure that it has the capacity to work with building owners and
                check their records. For LL97 to meet its policy goals, the
                agency enforcing it must have the proper inspectors and
                workforce to keep building owners on target. There is a
                significant risk of buildings failing compliance tests in the
                first years of implementation – OBEEP needs the funds necessary
                to counter this.{' '}
              </p>
              <p>
                Climate reparations must include retrofits of energy inefficient
                buildings – starting with long neglected public housing units in
                D22. The Climate Works for All plan provides mechanisms to
                access city, state, federal, and FEMA funds, and lays out a
                roadmap for creating 100,000 green jobs. The Council should
                implement retrofit ideas, such as funding for small,
                rent-regulated houses and NYCHA developments. Likewise, the
                Green New Deal for NYCHA (GND for NYCHA) presents great
                opportunities to reduce emissions among these buildings while
                maximizing and centering opportunities for jobs, contracting,
                and decision-making in public housing residents. Massive capital
                investment will pay for itself in energy savings and climate
                harm reduction.
              </p>
              <p>
                The New York City government should flex its purchasing power
                and inspire growth in local renewable energy production. This
                will provide building owners with much needed relief by
                expanding their choices in building electricity providers.
                Additionally, the City and state should increase funds to small
                businesses to install clean energy systems in buildings with
                more than 35% affordable housing units.
              </p>
              <p>
                It is critical that these projects employ local residents,
                particularly from historically marginalized communities, to
                retrofit buildings in their neighborhood – starting with public
                housing units – to include state-of-the-art windows, solar
                panels, batteries or other energy-saving mechanisms, and more.
                As we prepare for federal investments to retrofit buildings, we
                can be proactive and prepare New Yorkers for these
                opportunities. We should invest in the future of New York City
                by funding apprenticeship training and labor-management training
                programs across all sectors, and we should also coordinate with
                NYCHA to improve and upgrade their Jobs-Plus and REES programs
                for residents.
              </p>

              <h3>Climate & Sustainability In Our Schools:</h3>
              <p>
                <strong>Background: </strong>Climate education cannot be
                implemented in a vacuum. To address climate racism
                institutionally, we must desegregate our public schools and
                allocate resources more equitably. Overhaul of the system that
                upholds educational inequity is the only method of truly
                empowering Black, Brown, low-income and disabled students, and
                their empowerment is central to our reasoning for implementing
                climate education. They are the leaders whom we will need to
                innovate and execute just solutions to this problem, which they
                know from personal experience. A Green New Deal for Schools
                would retrofit school buildings and push for the City to develop
                and enhance its own curriculum and programs on sustainability
                education in-school.
              </p>
              <p>
                <strong>Solutions: </strong>A guiding light should be the newly
                unveiled national plan, “A Green Stimulus For K-12 Schools,”
                authored by the Climate + Community Project in partnership with
                Congressman Jamaal Bowman. The proposal calls for a $1.16
                trillion investment to fund climate-friendly retrofits at every
                K-12 public school nationwide, to hire and train more teachers,
                and to increase funding for low-income and disability-focused
                programs. If enacted, this plan would decrease carbon emissions,
                reduce class sizes, create more than 3 million jobs – from
                construction to teaching jobs – and support climate justice
                school curriculums. School retrofits would include installations
                of solar panels and batteries, repairs and upgrades to plumbing,
                lighting and other infrastructure, and renovations to improve
                student health by remediating lead, asbestos, mold and other
                harms. Such national legislation could work in concert with
                state and local legislation advancing sustainability and climate
                resilience efforts.
              </p>
              <p>
                It is in the interest of the City and all of our residents to
                teach children both the why’s and the how’s of sustainability
                and environmental justice. In April 2016, the New York City
                Council adopted Res. 0375, which calls on the New York State
                Department of Education to develop lessons on climate change for
                K-12 public schools’ curricula. However, as of the 2020-21
                school year, no such lessons have been standardized or required
                across the public school system. Too often, the onus to offer
                climate education falls solely on a given school’s
                Sustainability Coordinator – i.e., a teacher who serves as the
                designated school liaison to the New York City Department of
                Education Office of Sustainability.
              </p>
              <p>
                The City Council must also renew its pressure on the State to
                pass and implement climate education in New York public schools.
                Currently, three New York State Senate bills on climate
                education lie in committee. First, S6837 would establish a grant
                program to fund climate education or professional development
                training. Second, S7341 would mandate that the commissioner of
                education create a model K-12 environmental curriculum, with
                topics including climate change, environmental justice, and
                public health, to be taught in science, history, social studies,
                and health classes. Third, S596 would require the commissioner
                of education to make recommendations to the Board of Regents to
                adopt instruction of climate science in public high schools.
                S596 has garnered significant momentum through the organizing
                work of the Climate and Resilience Education Task Force (CRETF),
                a National Wildlife Federation initiative. S596 is also the only
                bill that seeks to ensure students understand climate change
                before graduating from the public school system. Local
                organizations including TREEage, Sunrise and Our Climate are
                working alongside CRETF to modify and improve the language of
                S596 to mandate that more climate topics are taught.
              </p>
              <p>
                Beyond providing for climate instruction, the City Council must
                authorize increased funding for the DOE Office of Sustainability
                to support hands-on environmental learning through grants and
                initiatives. The Office’s sustainability programming has
                expanded significantly in the last fiscal year. For example, it
                now oversees a Youth Leadership Council of high school students
                and also includes a Living Lab series, in which select schools
                teach sustainability using the school facility as an example
                (e.g. studying the school facility’s energy efficiency). The
                reach of these programs must increase dramatically to educate
                the majority of students who have yet to meaningfully engage in
                Office of Sustainability programming, especially students from
                environmental justice communities such as D22.{' '}
              </p>
              <p>
                To this end, the City Council should increase funding for the
                Office’s Sustainability Project Grant program, which awarded
                only $385K to 98 schools in 2020 for projects in environmental
                education, energy conservation and gardening, among other
                things. The Office relies on funds from the Demand Response
                Program, through which City agencies earn revenue by reducing
                their energy usage during peak hours. This year, additional
                funds came from the Mayor’s Office of Sustainability and the
                Department of Citywide Administrative Services-Division of
                Energy Management’s ExCEL Program. To support school-directed
                environmental education programming in the coming years, the
                City Council must provide substantial additional funding for the
                Office of Sustainability’s grant program.
              </p>
            </section>

            <section id="conclusion">
              <h2>Conclusion</h2>
              <p>
                The Green New Deal is in some ways a daunting proposition,
                requiring as it does much urgent action in many domains. That
                will be as true of our City’s and District’s renditions of the
                Green New Deal as it is of the national Green New Deal.
                Mitigating the challenges, however, will be both the massive
                federal funding for our efforts and the significance of what we
                will have achieved within months and even weeks of commencing.{' '}
              </p>
              <p>
                With more federal funding than has been forthcoming in many
                generations, residents of New York City’s 22nd District and of
                the City at large will now render each other’s lives far more
                sustainable – and far more worth sustaining – according to our
                own determinations than ever before.{' '}
              </p>

              <h3>Acknowledgements.</h3>
              <p>
                The Cabán for Queens Campaign is grateful to all those already
                doing the work of environmental justice, including: Sunrise
                Movement, Climate Works 4 All, Movement School, NY Renews,
                Sci4NY, TREEage, Astoria Pug, DSA-Ecosocialists, Open Streets,
                and more.
              </p>
            </section>
          </section>
        </div>
      </div>
    </div>

    <Footer className="mt-5" />
  </main>
)

export default GreenNewDeal

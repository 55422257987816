import React from 'react'
import { Link, graphql } from 'gatsby'

import PageLayout from '../layout/page'
import MetaSEO from '../components/MetaSEO'
import GreenNewDeal from '../layout/GreenNewDeal';


class GreenNewDealPage extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  formLoaded() {
  }

  render() {

    return (
      <PageLayout showBigCircle={false}>
        <MetaSEO title="Green New Deal" keywords={[`tiffany cabán`, `tiffany caban`, `caban for queens`, `caban`, `cabán`, `queens district attorney`, `district attorney`]}
          url="https://www.cabanforqueens.com/greennewdeal"
        />
        <GreenNewDeal {...{...this.props.data.page}} />
      </PageLayout>
    );

  }

}

// <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
//   <Image />
// </div>
export default GreenNewDealPage;

export const GreenNewDealPageQuery = graphql`
  query GreenNewDealPageQuery {
    page: markdownRemark (frontmatter: { uniq: {eq: "GreenNewDeal" }}) {
      html
      frontmatter {
        title
        subtitle
        spiel
      }
    }
  }

`;
